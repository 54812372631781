"use strict";

class serverObject {
   constructor({ serverUrl }) {
      this.activeRequests = {};
      this.serverUrl = serverUrl;
      const server = this;
      this.authenticateViaAppJwt = false;
      this.channel = "web";

      this.loadedAssets = [];

      window.addEventListener("load", (event) => {
         // Login submit listener
         const loginSubmit = document.getElementById("login-submit");
         if (loginSubmit) {
            loginSubmit.addEventListener("click", function () {
               server.submitLogin();
            });
         }

         // Login enter key listener
         var passwordInput = document.getElementById("log-in-password");
         if (passwordInput) {
            passwordInput.addEventListener("keydown", function (event) {
               // Check if the pressed key is Enter (key code 13)
               if (event.keyCode === 13) {
                  event.preventDefault();
                  server.submitLogin();
               }
            });
         }
      });
   }

   async send({
      sendData = {},
      url,
      encoded,
      successToastTitle,
      successToastMessage,
      errorToast,
      permissionToast,
      accessToast,
      refreshOnLogin,
      fileUpload,
      ignoreFailure,
   }) {
      let jwtToken;
      if (this.authenticateViaAppJwt) {
         //Wait for app to provide JWT
         jwtToken = this.authenticateViaAppJwt;
      }

      let request = new serverRequest({
         sendData,
         url,
         successToastTitle,
         successToastMessage,
         errorToast,
         permissionToast,
         accessToast,
         server: this,
         refreshOnLogin,
         fileUpload,
         jwtToken,
         channel: this.channel,
         ignoreFailure,
      });
      this.addRequest({ request });

      const object = this;
      try {
         //Get CSRF token & add to sendData
         sendData._csrf = await this.getCsrf({ ignoreFailure });

         const response = await request.send({ ignoreFailure });

         if (response.status == "error") {
            object.handleErrorToast({ error: response, errorToast });
         } else {
            object.handleSuccessToast({ successToastTitle, successToastMessage });
         }

         //If base64 encoded packet (to avoid content filters), decode
         if (encoded && response.status != "error") {
            let packet = this.decodeObscured({ data: response.responsePacket, shift: 32 });
            for (const key in packet) {
               response[key] = packet[key];
            }
         }

         return response;
      } catch (error) {
         object.handleErrorToast({ error, errorToast, permissionToast, accessToast });
         return { status: "error" };
      }
   }

   async getCsrf({ ignoreFailure }) {
      let tokenResponse = { ok: false };

      while (!tokenResponse.ok) {
         try {
            tokenResponse = await fetch(`${this.serverUrl}/csrf/getToken`, {
               method: "GET",
            });
         } catch (error) {
            console.log(error);
         }

         if (!tokenResponse.ok) {
            if (ignoreFailure) return;
            this.showConnectionError({ type: "csrf" });
            await this.connectionReset();
         }
      }

      return tokenResponse.text();
   }

   decodeObscured({ data, shift }) {
      data = data
         .split("")
         .map((char) => {
            const charCode = char.charCodeAt(0);
            if (charCode >= 65) {
               return String.fromCharCode(charCode - shift);
            } else {
               return char;
            }
         })
         .join("");
      return JSON.parse(data);
   }

   addRequest({ request }) {
      this.activeRequests[request.id] = request;
   }

   clearRequest({ id }) {
      delete this.activeRequests[id];
   }

   getRequest({ id }) {
      return this.activeRequests[id];
   }

   // iFrame embed
   setBearerToken({ token }) {
      this.bearerToken = token;
   }

   // app embed
   useAppJwt({ token }) {
      this.authenticateViaAppJwt = token;
      this.channel = "app";
   }

   async submitLogin() {
      document.getElementById("login-submit").classList.add("loading");
      const email = $(`#log-in-username`).val().trim();
      const password = $(`#log-in-password`).val().trim();
      let requestId = $(`#ajax-request-id`).val();
      if (requestId) requestId = requestId.trim();

      let request = new serverRequest({
         sendData: { email, password },
         url: "ajaxLogin",
         server: this,
      });
      this.addRequest({ request });
      const response = await request.send({});
      document.getElementById("login-submit").classList.remove("loading");

      if (response.outcome == "success") {
         closeModal("login");
         //Update csrf token
         // document.querySelector('meta[name="csrf-token"]').setAttribute("content", response.csrf);
         //document.cookie = `_csrf=${response.csrf}`;

         //If public page, log in
         if (typeof publicLogin !== "undefined" && publicLogin) {
            if (response.redirect || window.location.href.includes("#login") || !requestId) {
               let target = response.redirect ? response.redirect : window.location.href.replace("#login", "");
               return (window.location.href = target);
            }
         }

         const request = this.getRequest({ id: requestId });

         //If refresh on login
         if (request.refreshOnLogin) {
            return (window.location.href = window.location.href);
         }

         return request.sessionReceived();
      }
      //Add error message
      if (response.outcome == "failed") {
         if (response.reason == "credentials") {
            $(`#log-in-message .error-notice`).html(`${response.message}`);
            $(`.log-in-error`).removeClass("hide").html(`${response.message}`);
         }
         if (response.reason == "lockout") {
            $(`#log-in-message .error-notice`).html(
               `Your account has been locked for security reasons. We have sent you an email containing a link to unlock your account.`
            );
            $(`.log-in-error`)
               .removeClass("hide")
               .html(
                  `Your account has been locked for security reasons. We have sent you an email containing a link to unlock your account.`
               );
         }
      }
   }

   handleSuccessToast({ successToastTitle, successToastMessage }) {
      if (!successToastTitle && !successToastMessage) return;

      if (successToastTitle === true) {
         successToastTitle = "Updated";
      }

      stepCore.toast({
         type: "success",
         duration: 4000,
         heading: successToastTitle,
         message: successToastMessage ? successToastMessage : "",
      });
   }

   handleErrorToast({ error, errorToast, permissionToast, accessToast }) {
      if (error.reason == "session") return;
      let icon = error.reason == "authorization" ? "access" : "error";
      let message;

      let errorToastTitle;
      if (errorToast) {
         errorToastTitle = errorToast;
      } else {
         errorToastTitle = "An error has occurred";
         message = "Our tech team have been notified";
      }

      if (error.reason == "authorization") {
         errorToastTitle = "You don't have access for this";
         message = "Contact your administrator";
      }

      stepCore.toast({
         type: "error",
         duration: 7000,
         heading: errorToastTitle,
         message,
      });
   }

   async loadResources({ resources, version }) {
      //Warn if internal URLs without version provided
      if (!["app", "vendor"].includes(version) && resources.some((url) => url.match(/^\//))) {
         console.error(`Warning: version not provided when loading resources ${resources.toString()}`);
      }

      // Dynamically map resources to loadJSFile promises
      const resourcePromises = resources.map((path) => {
         if (path.match(/\.js$/)) {
            return this.loadJSFile({ path, version, useAsync: true });
         }
         if (path.match(/\.css$/)) {
            return this.loadCssFile({ path, version });
         }
      });

      // Wait for all resources to load
      await Promise.all(resourcePromises);
   }

   loadJSFile({ path, nonce, version, useAsync }) {
      if (version == "app") {
         path = path.replace(/js$/i, "") + "v" + stepCore.version.appVersion.toString().replace(".", "") + ".js";
      }
      if (version == "vendor") {
         path = path.replace(/js$/i, "") + "v" + stepCore.version.vendorVersion.toString().replace(".", "") + ".js";
      }
      if (this.loadedAssets.includes(path)) return Promise.resolve(true);

      const component = this;
      return new Promise(async function (resolve, reject) {
         let complete = false;
         var script = document.createElement("script");
         if (nonce) script.nonce = nonce;
         script.src = `${window.location.origin}${path}`;
         script.async = useAsync;

         script.onreadystatechange = function () {
            if (script.readyState === "complete" || script.readyState === "complete") {
               script.onreadystatechange = null;
               complete = true;
               component.loadedAssets.push(path);
               resolve({ status: "success" });
            }
         };

         // Old browsers
         script.onload = function () {
            complete = true;
            component.loadedAssets.push(path);
            resolve({ status: "success" });
         };

         // error event
         script.addEventListener("error", (e) => {
            complete = true;
            console.log(`Load JS file (probably 404)1: ${path}`);
            component.logJsLoadFailure({ path, error: "404" });
            resolve({ status: "error" });
         });

         // append and execute script
         document.head.appendChild(script);

         //No response handling
         setTimeout(() => {
            if (complete) return;
            console.log(`Load JS file: No response ${path}`);
            component.logJsLoadFailure({ path, error: "timeout" });
            resolve({ status: "error" });
         }, 1000 * 20);
      });
   }

   logJsLoadFailure({ path, error }) {
      stepCore.toast({
         type: "error",
         duration: 5000,
         heading: "Problem loading page",
      });

      fetch(`${this.serverUrl}/errors`, {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
         },
         body: JSON.stringify({
            url: path,
            data: { error: `JS resource not loaded: ${error}` },
         }),
      });
   }

   loadCssFile({ path, nonce, version }) {
      if (version == "app") {
         path = path.replace(/css$/i, "") + "v" + stepCore.version.appVersion.toString().replace(".", "") + ".css";
      }
      if (version == "vendor") {
         path = path.replace(/css$/i, "") + "v" + stepCore.version.vendorVersion.toString().replace(".", "") + ".css";
      }

      if (this.loadedAssets.includes(path)) return Promise.resolve(true);
      const component = this;
      return new Promise(async function (resolve, reject) {
         let link = document.createElement("link");
         link.type = "text/css";
         link.rel = "stylesheet";
         if (nonce) link.nonce = nonce;
         link.onload = () => {
            component.loadedAssets.includes(path);
            resolve({ status: "success" });
         };
         link.onerror = () => reject({ status: "error" });
         link.href = `${window.location.origin}${path}`;
         let headScript = document.querySelector("script");
         headScript.parentNode.insertBefore(link, headScript);
      });
   }

   updateServerUrl({ serverUrl }) {
      this.serverUrl = serverUrl;
   }

   showConnectionError({ type = "request" }) {
      console.log(type);
      new stepCore.components.dialogComponent({
         parent: this,
         insertClass: "modal-light-blue",
         text: ` 
         <div class="big-icon-container"><div class="big-icon icon-wifi"></div></div> 
         <div class="notice-bar error">You are not connected to the internet</div> 
         <div class="instruction">Please check your connection and try again.</div>`,
         buttons: [
            {
               key: "retryConnection",
               label: "Try again",
               icon: "reverse",
               class: "full-width",
            },
         ],
         externalListeners: [
            {
               type: "dialogSelection",
               action: type == "request" ? "retryConnection" : "retryCsrf",
            },
         ],
      });
   }

   childEventHandler({ action, data }) {}

   connectionReset() {
      const request = this;
      return new Promise((resolve, reject) => {
         request.resetConnection = resolve;
      });
   }
}

class serverRequest {
   constructor({
      sendData,
      url,
      successToastTitle,
      successToastMessage,
      errorToast,
      permissionToast,
      accessToast,
      server,
      refreshOnLogin,
      fileUpload,
      jwtToken,
      channel,
   }) {
      this.sendData = sendData;
      this.url = `${server.serverUrl}/${url}`;
      if (url.startsWith("http")) this.url = url;
      this.successToastTitle = successToastTitle;
      this.successToastMessage = successToastMessage;
      this.errorToast = errorToast;
      this.permissionToast = permissionToast;
      this.accessToast = accessToast;
      this.id = Math.floor(Math.random() * 99999999);
      this.server = server;
      this.refreshOnLogin = refreshOnLogin;
      this.fileUpload = fileUpload;
      this.jwtToken = jwtToken;
      this.attempts = 0;
      this.resetConnection;

      if (!this.fileUpload) {
         this.headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
         };

         //Use JWT from app where provided
         if (jwtToken) {
            this.headers.Authorization = `Bearer ${jwtToken}`;
         }

         //Use token from iframe where embedded
         if (this.server.bearerToken) {
            this.headers.Authorization = `Bearer ${this.server.bearerToken}`;
         }

         this.headers.channel = channel;
      }
   }

   send({ ignoreFailure }) {
      const request = this;
      return new Promise(async function (resolve, reject) {
         //Send AJAX request
         try {
            let response;
            request.attempts++;

            if (request.fileUpload) {
               response = await request.sendFormData();
            }

            if (!request.fileUpload) {
               response = await request.fetch();
            }

            if (!response.ok) {
               if (ignoreFailure) {
                  return resolve({ status: "error" });
               }

               //Handle network errors
               if (response.status == "network") {
                  while (response.status == "network") {
                     request.showConnectionError({});
                     await request.connectionReset();

                     //Get new csrf
                     request.sendData._csrf = await request.server.getCsrf({ ignoreFailure: true });

                     //If failure, and ignore failures on exit here
                     if (!request.sendData._csrf) {
                        resolve({ status: "error", reason: "csrf", message: "fail" });
                        return request.server.clearRequest({ id: request.id });
                     }

                     response = await request.fetch();
                  }
               }

               //JWT rejection
               if (request.jwtToken && response.status == 401) {
                  if (request.attempts > 1) {
                     throw new Error(401);
                  }
                  console.log(`JWT rejected: retry`);

                  //Get new JWT from app
                  const jwtRequestResponse = await appApi.send({ api: "refreshJwtToken" });

                  if (jwtRequestResponse.status == "success") {
                     request.jwtToken = jwtRequestResponse.jwtToken;
                     request.server.useAppJwt({ token: jwtRequestResponse.jwtToken });
                     request.headers.Authorization = `Bearer ${jwtRequestResponse.jwtToken}`;

                     //Retry request
                     response = await request.fetchTimeout();
                  }
               }

               //Session
               if (response.status == 401) {
                  console.log(`401 error: session`);
                  throw new Error(401);
               }

               //Authorization
               if (response.status == 403) {
                  console.log(`403 error: authorization`);
                  throw new Error(403);
               }

               //CSRF token
               if (response.status == 406) {
                  console.log(`406 error: CSRF token`);
                  throw new Error(406);
               }

               //Invalid url
               if (response.status == 404) {
                  console.log(`404 error: ${request.url}`);
                  throw new Error(404);
               }

               if (response.status == "network") {
                  console.log(`Network error: probably no connection`);
                  throw new Error("network");
               }

               if (!response.ok) {
                  throw new Error("other");
               }
            }

            //Process response

            let responseBody;
            try {
               if (request.fileUpload) {
                  responseBody = response;
               } else {
                  responseBody = await response.json();
               }
            } catch (error) {
               //Assume application error if failed to fetch
               throw new Error("application");
            }

            //Handle server errors
            if (responseBody.status != "success") {
               throw new Error(responseBody.message ? responseBody.message : "application");
            }

            //Handle successful request
            if (responseBody.status == "success") {
               resolve(responseBody);
               //Clear request from memory
               request.server.clearRequest({ id: request.id });
            }
         } catch (error) {
            //Send logs
            let logReason = "unknown";
            if (error.message == "session") {
               logReason = "session";
            } else if (error.message == 401) {
               logReason = "session";
            } else if (error.message == 403) {
               logReason = "authorization";
            } else if (error.message == 406) {
               logReason = "csrf";
            } else if (error.message == 404) {
               logReason = 404;
            } else if (error.message == "application") {
               logReason = "application";
            } else if (error.message == "The user aborted a request.") {
               logReason = "timeout";
            } else {
               logReason = "application";
            }

            //Handle not logged in
            if (logReason == "session") {
               const response = await request.handleExpiredSession({ ignoreFailure });
               resolve(response);
               //Clear request from memory
               return request.server.clearRequest({ id: request.id });
            }

            resolve({ status: "error", reason: logReason, message: error.message });
         }
      });
   }

   sendFormData() {
      const request = this;
      return new Promise(async function (resolve, reject) {
         const formData = new FormData();
         for (const key in request.sendData) {
            const item = request.sendData[key];

            if (Array.isArray(item) && item[0] instanceof File) {
               for (const file of item) {
                  formData.append(key, file);
               }
            } else {
               formData.append(key, item);
            }
         }

         for (const pair of formData.entries()) {
            //  console.log(pair[0], pair[1]);
         }

         let url = request.url;
         if (request.sendData.image && !request.sendData.files) url += `?type=image`;
         if (request.sendData.files && !request.sendData.image) url += `?type=file`;
         if (request.sendData.image && request.sendData.files) {
            url += `?type=mixed`;
            //Add image to files array
            formData.append("files", request.sendData.image);
            //Remove image
            formData.delete("image");
         }

         fetch(url, {
            method: "POST",
            body: formData,
         })
            .then((response) => response.json())
            .then((data) => {
               data.ok = true;
               resolve(data);
            })
            .catch((error) => {
               reject(error);
            });
      });
   }

   async fetch() {
      const ms = 25000; //Timeout
      const options = {
         method: "POST",
         headers: this.headers,
         body: JSON.stringify(this.sendData),
         credentials: this.server.bearerToken ? "omit" : "same-origin",
      };

      const controller = new AbortController();
      const { signal } = options;
      const id = setTimeout(() => controller.abort(), ms);

      // Add original signal's abort event listener if provided
      if (signal) {
         const abortHandler = () => controller.abort();
         signal.addEventListener("abort", abortHandler);

         // Clean up
         options.signal = new AbortSignal().follow(controller.signal).follow(signal);
         options.signal.addEventListener("abort", () => clearTimeout(id));
      } else {
         options.signal = controller.signal;
      }

      try {
         const response = await fetch(this.url, options);
         clearTimeout(id); // Clear timeout on successful fetch
         if (!response.ok) {
            // Server responded with an HTTP error status
            return { status: response.status, message: "HTTP error response." };
         }
         return response; // Return the fetch response directly for successful requests
      } catch (error) {
         clearTimeout(id); // Ensure timeout is cleared on error
         if (error.name === "AbortError") {
            return { status: "network", message: "Request was aborted due to timeout." };
         } else {
            // Assume network error for any other fetch failure
            return { status: "network", message: "Network error, possibly due to no internet connectivity." };
         }
      }
   }

   sendErrorLog({ error }) {
      fetch(`${this.server.serverUrl}/errors`, {
         method: "POST",
         headers: this.headers,
         body: JSON.stringify({
            url: this.url,
            data: this.sendData,
            error,
         }),
      });
   }

   async handleExpiredSession({ ignoreFailure }) {
      const ajaxRequestId = document.querySelector("#ajax-request-id");
      if (!ajaxRequestId) return;
      ajaxRequestId.value = this.id;
      $(`#log-in-message .error-notice`).html(`You have been logged out due to inactivity. Please log in again`);
      openModal("login");

      //Await session
      await this.getLoggedInSession;

      //Get new CSRF token & add to sendData
      this.sendData._csrf = await this.server.getCsrf({});

      //On session received, try resending
      const response = await this.send({ ignoreFailure });

      return response;
   }

   getLoggedInSession = new Promise((resolve, reject) => {
      Object.defineProperty(this, "_sessionReceived", {
         set: (value) => {
            if (value) {
               resolve();
            }
         },
      });
   });

   sessionReceived() {
      this._sessionReceived = true;
   }

   showConnectionError({ type = "request" }) {
      console.log(type);
      new stepCore.components.dialogComponent({
         parent: this,
         insertClass: "modal-light-blue",
         text: ` 
         <div class="big-icon-container"><div class="big-icon icon-wifi"></div></div> 
         <div class="notice-bar error">You are not connected to the internet</div> 
         <div class="instruction">Please check your connection and try again.</div>`,
         buttons: [
            {
               key: "retryConnection",
               label: "Try again",
               icon: "reverse",
               class: "full-width",
            },
         ],
         externalListeners: [
            {
               type: "dialogSelection",
               action: type == "request" ? "retryConnection" : "retryCsrf",
            },
         ],
      });
   }

   childEventHandler({ action, data }) {
      if (action == "retryConnection") {
         this.resetConnection();
         this.resetConnection = null;
      }
      if (action == "retryCsrf") {
         this.resetConnection();
         this.resetConnection = null;
      }
   }

   connectionReset() {
      const request = this;
      return new Promise((resolve, reject) => {
         request.resetConnection = resolve;
      });
   }
}

const server = new serverObject({
   serverUrl: typeof serverUrl !== "undefined" ? serverUrl : window.location.protocol + "//" + window.location.host,
});

module.exports = server;
